import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ComsingSoon from './Modules/ComingSoonPage/ComingSoon';
import './index.css'



ReactDOM.render(
  <>

    <App  />
    {/* <ComsingSoon/> */}
    
  
    </>,
  document.getElementById('root')
);

